<template>
    <div class="interventions-home">

        <h2 class="text-secondary">{{ page.title }}</h2>

        <div class="row mb-5">
            <div class="col-12 col-lg-7 order-1 order-lg-0">

                <div v-html="page.content"></div>

            </div>
            <div class="col-12 col-lg-5 order-0 order-lg-1">

                <div v-if="page.videoEmbed">
                    <Video :embed="page.videoEmbed" />
                </div>

            </div>
        </div>

        <Themes @click-theme="chooseTheme" btn_label="Lees verder" />

    </div>
</template>

<script>
    // @ is an alias to /src
    import Themes from '@/components/Themes.vue';
    import api from '@/data/api'
    import Video from '@/components/Video.vue';

    export default {
        name: 'Kennisbank',
        components: {
            Themes,
            Video
        },
        methods: {
            chooseTheme(theme) {
                const _this = this;
                
                this.emitter.emit('choose-role', { 
                    theme: theme, 
                    success() {
                        _this.$router.push({ path: `/kennisbank/${theme.slug}` });
                    } 
                });
            }
        },
        data() {
            return {
                pageTitle: 'Kennisbank',
                page: {}
            }
        },
        mounted() {
            api.fetchPage(5576).then(response => {
                this.page = response;
            });

            const breadcrumbs = [
                {
                    'label': 'Home',
                    'path': '/'
                },
                {
                    'label': 'Kennisbank',
                },
            ];

            this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
        }
    }
</script>
